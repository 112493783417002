import React, { useState, useContext, useMemo, useRef, useCallback } from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  DropdownButton,
  Dropdown,
  Modal,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { useEffect } from "react";
import API from "api";
import GQAPI from "views/GraphQL/gqApi";
import { CSVLink } from "react-csv";
import { Importer, ImporterField } from "react-csv-importer";
import { Link } from "react-router-dom";
import { PDFViewer } from "@react-pdf/renderer";
import PDF from "components/PDF";
import Filter from "components/Filter";
import TimeFilter from "components/TimeFilter";
import FilterSelect from "components/FilterSelect";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import HeaderItem from "components/Table/HeaderItem";
import PGPagination from "components/Pagination/PGPagination";
import { Storage } from "aws-amplify";
import {
  PAGE_LIMIT,
  RESULT_COLOR,
  TEST_HEADER_TYPE,
  newRoleOptions,
  SUPER_ADMIN_ID,
  RESULT_VALUE,
  TEST_EMPTY_OBJECT,
  TEST_TYPE_VALUE,
} from "constant";
import {
  changeSort,
  formatPhoneNumber,
  formatTest,
  sortTestList,
  sortingFilterInLC,
  formatDateOfBirthDOB,
  getDemoGraphics,
  calculateTdWidth,
} from "utils";
import { AppContext } from "context/app-context";
import PDFLayout from "components/PDF/PDFLayout";
import ExportToExcelData from "components/ExportToExcelData";
import TestPDFLayout from "components/PDF/TestPDFLayout";
import Header from "components/Table/Header";
import moment from "moment";
import ContactInfo from "components/Modal/ContactInfo";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import ChangeResultModal from "components/Modal/ChangeResultModal";
import Loader from "components/Loader/Loader";
import ChangeTestTypeModal from "components/Modal/ChangeTestTypeModal";
import TestModal from "components/Modal/TestModal";
import ChangeTestLabModal from "components/Modal/ChangeTestLabModal";
import AppTestLogsModal from "components/Modal/AppTestLogsModal";
import TestLogsModal from "components/Modal/TestLogsModal";
import ChangeTestConfigModal from "components/Modal/ChangeTestConfigModal";
import { debounce } from "debounce";
import { useInitialRender } from "components/useInitialRender";
import Icon from "components/Icon";
import ViewOnlyCheckBox from "components/CheckBox/ViewOnlyCheckBox";
import { stringConstant } from "constant";
import { CSVReader } from "react-papaparse";
import { CONFIG } from "constant";
import ImportResultModal from "components/Modal/ImportResultModal";
import SequenceNo from "components/SequenceNo";
import { saveAs } from "file-saver";
import EditAccessionNoModal from "components/Modal/EditAccessionNoModal";
import CalRedieLogsModal from "components/Modal/CalredieLogsModal";
import { exportToCalRedie } from "utils";
import { downloadDataAsCSV } from "utils";

const AdminTotalTestApi = () => {
  const mainRef = useRef();
  const [width, setWidth] = useState(0);
  const history = useHistory();

  useEffect(() => {
    setWidth(mainRef.current?.offsetWidth);
  }, [mainRef]);

  const handleResize = () => {
    setWidth(mainRef.current?.offsetWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const importResult = [];
  const [totalRecord, setTotalRecord] = useState(0);
  const [tests, setTests] = useState([]);
  const [filteredTests, setFilteredTests] = useState([]);
  const [labs, setLabs] = useState([]);
  const [sites, setSites] = useState([]);
  const [moreInfo, setMoreInfo] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [testsLab, setTestsLab] = useState();
  const [testPdf, setTestPdf] = useState();
  const [showPdf, setShowPdf] = useState(false);
  const [timeFilter, setTimeFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [triggerFilter, setTriggerFilter] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [showTestTtypeModal, setShowTestTypeModal] = useState(false);
  const [showLabChangeModal, setShowLabChangeModal] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [clientChangeModal, setClientChangeModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [testAppLogs, setTestAppLogs] = useState([]);
  const [showAppLog, setShowAppLog] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [openCreator, setOpenCreator] = useState(false);
  const [newTestCreate, setTestCreate] = useState(_.cloneDeep(TEST_EMPTY_OBJECT));
  const [editAccessionNo, setEditAccessionNo] = useState(null);
  const [selectedTest, setSelectedTest] = useState([]);
  const [openUploadLogsModal, setOpenUploadLogsModal] = useState(false);

  const { filterDates } = TimeFilter;

  const location = useLocation();
  const initialRender = useInitialRender();
  const appContext = useContext(AppContext);

  const searchTerms = CONFIG.isWhiteLabel
    ? [
        "First Name",
        "Last Name",
        "Date of Birth",
        "Email",
        "Phone",
        `${stringConstant.show}`,
        "Status",
        "Result",
        "Test Type",
        `${stringConstant.sequenceNo}`,
        "Tester",
        "Submitted to H&S",
      ]
    : [
        "First Name",
        "Last Name",
        "Date of Birth",
        "Email",
        "Phone",
        "Client",
        `${stringConstant.show}`,
        "Lab",
        "Status",
        "Result",
        "Test Type",
        `${stringConstant.sequenceNo}`,
        "Tester",
        "Reference ID",
        "isVaccinated",
        "White Glove",
        "Pattern",
        "Capacity",
      ];

  const selectedItem = [
    "createdAt",
    "firstName",
    "lastName",
    "dob",
    "email",
    "phoneNumber",
    "clientID",
    "siteID",
    "labID",
    "status",
    "result",
    "test_type",
    `site_name`,
    "tester_name",
    "isVaccinated",
    "capacity",
    "whiteGlove",
    "patternTestAnswer",
  ];

  if (!appContext) {
    history.push("/login");
  }

  const displayPdf = async (test) => {
    handleSaveFilter();
    const empID = await appContext.getEmployeeID(test.employeeID);
    const t = test;
    t.empID = empID;
    appContext.getLab(test).then(async (resp) => {
      setTestsLab(resp);
      setShowPdf(true);
    });
    setTestPdf(formatTest(test));
  };

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(sortBy === by ? !sortDescending : true);
  };

  const testsToMap = useMemo(() => {
    const list = filteredTests ? [...filteredTests] : [];
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredTests, currentPage]);

  const loadTestFromPG = async (params, dateFilter, page, orderBy, orderByDirection) => {
    setLoading(true);
    const filterParam = params;
    try {
      Object.assign(filterParam, { page: page });
      if (dateFilter) {
        const { start, end } = dateFilter;
        if (start)
          Object.assign(filterParam, { startDate: moment(start.format("YYYY-MM-DD")).startOf("day").toISOString() });
        if (end) Object.assign(filterParam, { endDate: moment(end.format("YYYY-MM-DD")).endOf("day").toISOString() });
      }
      if (orderBy) {
        Object.assign(filterParam, { orderBy, orderByDirection });
      }

      const pgList = await API.getTestListFromPG(filterParam);
      setLoading(false);

      // const employees = await API.getEmployees();

      const ttlTests = tests.concat(pgList.rows.map((t) => formatTest(t)));
      if (pageNo === 1) {
        setTotalRecord(pgList.total);
      }
      setAllSelected(false);
      setTests(ttlTests);
      refreshTestList(ttlTests);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const savedFilters = appContext.testFilter;

    const sortingFilters = sortingFilterInLC.get();
    if (sortingFilters.totaltests.sortBy) {
      setSortDescending(sortingFilters.totaltests.sortDescending);
      setSortBy(sortingFilters.totaltests.sortBy);
    }
    if (savedFilters && savedFilters.location === "totaltests") {
      if (Object.keys(savedFilters.filter).length > 0) {
        setFilter(savedFilters.filter);
        setShowFilter(true);
      }
      if (Object.keys(savedFilters.timeFilter).length > 0) {
        setTimeFilter(savedFilters.timeFilter);
      }
    }
  }, []);

  useEffect(() => {
    setSites(appContext.sites);
    setLabs(appContext.labs);
  }, [appContext.sites, appContext.labs]);

  useEffect(() => {
    const prevSortingState = sortingFilterInLC.get();
    prevSortingState["totaltests"] = { filter, timeFilter, sortBy, sortDescending };
    sortingFilterInLC.save(prevSortingState);
    if (filteredTests.length === 0) return;
  }, [sortBy, sortDescending]);

  useEffect(() => {
    setPageNo(1);
    fetchFromApi({ ...filter }, { ...timeFilter }, 1, sortBy, sortDescending);
  }, [filter, timeFilter, sortBy, sortDescending]);

  useEffect(() => {
    if (pageNo > 1) {
      loadTestFromPG(Object.keys(filter).length === 0 ? { id: 1 } : filter, timeFilter, pageNo, sortBy, sortDescending);
    }
  }, [pageNo]);

  const fetchFromApi = useCallback(
    debounce((filter, timeFilter, page, sortBy, sortDescending) => {
      loadTestFromPG(filter, timeFilter, page, sortBy, sortDescending);
    }, 800),
    []
  );

  useEffect(() => {
    if (initialRender) return;
    if (appContext.testObject && appContext.testObject !== 0) {
      const eventType = appContext.testObject.opType;
      const model = formatTest(appContext.testObject.element);
      const newList = [...filteredTests];
      if (eventType === "INSERT") {
        newList.unshift(model);
      } else if (eventType === "UPDATE") {
        const index = newList.findIndex((t) => t.id === model.id);
        console.log("index", index);
        if (index !== -1) {
          newList.splice(index, 1, model);
        }
      } else if (eventType === "DELETE") {
        const index = newList.findIndex((t) => t.id === model.id);
        if (index !== -1) {
          newList.splice(index, 1);
        }
      }
      const testAfterFilter = filterDates(nestedFilter([...newList], filter), timeFilter);
      if (filteredTests.length < testAfterFilter.length && eventType === "INSERT") {
        setTotalRecord(totalRecord + 1);
      }
      refreshTestList(testAfterFilter);
    }
  }, [appContext.testObject]);

  useEffect(() => {
    if (location.state) {
      if (location.state.term === "Lab") setLabs(location.state.models);

      setShowFilter(true);

      let filterTerm = location.state.term;
      let filterTermName = location.state.name;

      if (filterTerm === "Show") {
        const obj = location.state.models.find((c) => c.name === filterTermName);
        if (obj) {
          filterTerm = "ShowID";
          filterTermName = obj.id;
        }
      }
      if (filterTerm === "referenceID") {
        const obj = location.state.models.find((c) => c.name === filterTermName);
        if (obj) {
          filterTerm = "referenceID";
          filterTermName = obj.id;
        }
      }

      if (location.state.positiveTests) {
        let resultValue = "positive";
        if (filterTerm === "Client") {
          const obj = location.state.models.find((c) => c.name === filterTermName);
          if (obj && obj.resultType === "P/F") resultValue = "fail";
        }
        setFilter({
          ...filter,
          [filterTerm]: filterTermName,
          result: "positive",
        });
      } else if (location.state.negativeTests) {
        let resultValue = "negative";
        if (filterTerm === "Client") {
          const obj = location.state.models.find((c) => c.name === filterTermName);
          if (obj && obj.resultType === "P/F") resultValue = "pass";
        }
        setFilter({
          ...filter,
          [filterTerm]: filterTermName,
          result: resultValue,
        });
      } else {
        setFilter({ ...filter, [filterTerm]: filterTermName });
      }
      setTriggerFilter(true);
      setTimeout(() => setShowFilter(false), 1);
    }
  }, [location.state]);

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        console.log("key::::", key);
        if (!filters[key].length) {
          return true;
        }
        if (key === "phoneNumber") {
          return obj[key] && obj[key].replace(/\D/g, "").includes(filters[key].replace(/\D/g, ""));
        }
        if (key === "result") {
          return obj[key] && obj["viewedResult"].toLowerCase().includes(filters[key]);
        }
        if (key === "Lab") {
          return obj.labID && appContext.getLabName(obj.labID).toLowerCase().includes(filters[key].toLowerCase());
        }

        if (key === "Show") {
          const siteIds = appContext.sites
            .filter((s) => s.name.toLowerCase().includes(filters[key].toLowerCase()))
            .map((s) => s.id);
          return siteIds.includes(obj.siteID);
        }
        if (key === "ShowID") {
          return obj.siteID === filters[key];
        }
        if (key === "Client") {
          return (
            obj.clientID && appContext.getClientName(obj.clientID).toLowerCase().includes(filters[key].toLowerCase())
          );
        }
        if (key === "referenceID") {
          return obj[key] && obj["referenceID"].includes(filters[key]);
        }
        if (key === "pattern" || key === "Pattern") {
          if (filters[key] === "1") return obj["patternTestAnswer"] && obj["patternTestAnswer"] !== "undefined";

          if (filters[key] === "0") return obj["patternTestAnswer"] == "undefined" || obj["patternTestAnswer"] == "";
        }

        if (key === "result") {
          return obj[key] && obj["viewedResult"].toLowerCase().includes(filters[key]);
        }

        if (key === "sequenceNo") {
          return obj[key] && obj["sequenceNo"].toString().includes(filters[key]);
        }

        if (key === "dob") {
          return obj[key] && obj[key] === filters[key];
        }

        if (key === "test_type" && filters[key] && filters[key].length > 0) {
          return obj[key] && filters[key].indexOf(obj[key]) !== -1;
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
    return models;
  };

  const refreshTestList = (list) => {
    if (sortBy) {
      setFilteredTests(
        sortTestList(
          sortBy,
          sortDescending,
          list,
          appContext.getSiteName,
          appContext.getLabName,
          appContext.getClientName,
          appContext.parseResult
        )
      );
    } else {
      setFilteredTests(list);
    }
  };

  const handleUploadLogs = () => {
    setOpenUploadLogsModal(true);
  };

  const handleChangePage = (page, direction) => {
    setCurrentPage(page);
    const currentRecord = filteredTests.length;
    if (direction === "next" && page === totalPages && totalRecord > 0 && currentRecord < totalRecord) {
      setPageNo(pageNo + 1);
    }
  };

  const handleSaveFilter = () => {
    appContext.setTestFilter({ location: "totaltests", filter, timeFilter });
  };

  const handleCheckboxChange = (e, id, test) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    const prevFilteredTests = selectedTest.filter((t) => t.id !== id);
    if (e.target.checked) {
      filteredList.push(id);
      setSelectedTest([...prevFilteredTests, test]);
    } else {
      setSelectedTest([...prevFilteredTests]);
    }
    setCheckboxes(filteredList);
  };

  const toggleCheckboxes = (val) => {
    if (val) {
      const newIds = [...checkboxes];
      const newTests = [...selectedTest];

      filteredTests.forEach((t) => {
        if (newIds.indexOf(t.id) === -1) {
          newIds.push(t.id);
          newTests.push(t);
        }
      });
      setCheckboxes(newIds);
      setSelectedTest(newTests);
    } else {
      const newIds = checkboxes.filter((c) => filteredTests.findIndex((t) => t.id === c) === -1);
      const newTests = selectedTest.filter((test) => newIds.includes(test.id));
      setCheckboxes(newIds);
      setSelectedTest(newTests);
    }
  };

  const createMoreInfo = (type, id) => {
    let infoObj = null;
    if (type === "site") infoObj = appContext.sites.find((site) => site.id === id);
    else if (type === "client") infoObj = appContext.clients.find((client) => client.id === id);
    else infoObj = labs.find((lab) => lab.id === id);
    if (!infoObj) return;
    setMoreInfo({
      type,
      name: infoObj.name,
      contact: infoObj.contact_name,
      phone: infoObj.contact_phone || infoObj.phone_number,
      email: infoObj.contact_email,
    });
    setModalShow(true);
  };

  const handleCloseContactInfo = () => {
    setMoreInfo({});
    setModalShow(false);
  };

  const handleExportCalrediePatient = async () => {
    try {
      setLoading(true);
      if (checkboxes.length === 0) {
        const resFilePathc = await API.exportToCalRedieData({ date: moment().format("YYMMDD") });
        console.log("resFilePathc", resFilePathc);
        const resut = await Storage.get(`csvfile/${resFilePathc}`, {
          bucket: "employee-external-tests",
        });
        const response = await fetch(resut);
        const blob = await response.blob();

        console.log("fileMetadata", blob.size);
        if (blob.size === 0) {
          appContext.showErrorMessage("No Pending Data For Submit to Calredie");
          setLoading(false);
          return;
        }
        saveAs(blob, `biomobilelab_${resFilePathc}`);
      } else {
        const tests = filteredTests.filter((f) => f.result && checkboxes.includes(f.id));
        if (tests.length === 0) {
          appContext.showErrorMessage("All tests results Already Submitted!");
          setLoading(false);
          return;
        }
        const results = exportToCalRedie(tests);
        downloadDataAsCSV(results, `${moment().toISOString()}_Cal_Patients`);
      }

      setLoading(false);
    } catch (err) {
      console.log("Error:-", err);
      setLoading(false);
    }
  };

  const handleDeleteConfirm = async (isConfirm) => {
    setOpenDeleteConfirmation(false);
    if (!isConfirm) {
      return;
    }
    setLoading(true);
    const testList = selectedTest.filter((test) => checkboxes.includes(test.id));
    let ttlTest = testList.length;
    if (ttlTest === 0) {
      return;
    }

    await appContext.deleteTests(testList);
    const logs = testList.map((e) => {
      return {
        id: e.id,
        slug: "Deleted",
        sequenceNo: e.sequenceNo,
        userName: appContext.user.name,
        userID: appContext.user?.sub,
      };
    });
    await GQAPI.addDeleteLogs(logs);
    setLoading(false);
    const tmpList = filteredTests.filter((t) => !checkboxes.includes(t.id));
    setFilteredTests(tmpList);
    setTotalRecord(totalRecord - ttlTest);
    clearSelection();
  };

  const clearSelection = () => {
    setAllSelected(false);
    setCheckboxes([]);
    setSelectedTest([]);
    setLoading(false);
  };

  const updateTestType = async (selectedType) => {
    const updateSelectedTestResult = selectedTest.filter((test) => checkboxes.includes(test.id));
    const ttlTest = updateSelectedTestResult.length;
    if (!ttlTest) {
      return;
    }
    setLoading(true);

    try {
      if (!loading) setLoading(true);
      const testList = updateSelectedTestResult.map((t) => {
        return {
          id: t.id,
          test_type: selectedType,
          sequenceNo: t.sequenceNo,
        };
      });
      await GQAPI.updateTestType(testList);
      const updateInHR = updateSelectedTestResult
        .filter((f) => f.schrID)
        .map((t) => {
          return {
            id: t.id,
            schrID: t.schrID,
            value: selectedType,
            label: TEST_TYPE_VALUE[selectedType],
            prevTestType: t.test_type,
            sequenceNo: t.sequenceNo.toString(),
            result: t.result,
            date: t.createdAt,
            isDone: true,
          };
        });
      await API.changeTestType(updateInHR, appContext.user.name);

      appContext.showSuccessMessage(
        `${ttlTest} test${ttlTest === 1 ? "" : "s"} type has been changed to ${selectedType}`
      );
      addLogs(updateSelectedTestResult, "TestTypeChange", selectedType);
    } catch (err) {
      appContext.showErrorMessage(`Fail to update the test type`);
    }
    clearSelection();
  };

  const updateLabChange = async (selectedType, isSubmitToLab) => {
    const updateSelectedTestResult = selectedTest.filter((test) => checkboxes.includes(test.id));
    const ttlTest = updateSelectedTestResult.length;
    if (!ttlTest) {
      appContext.showErrorMessage("Please select test");
      return;
    }
    setLoading(true);
    try {
      let testList = [];
      if (!loading) setLoading(true);
      if (isSubmitToLab) {
        testList = updateSelectedTestResult.map((t) => {
          return {
            id: t.id,
            labID: selectedType.value,
            labName: selectedType.label,
            status: "Sent",
            submittedBy: appContext.user.sub,
            submittedByName: appContext.user.name,
            sequenceNo: t.sequenceNo,
          };
        });
        await GQAPI.updateTestLab(testList);
        const lab = appContext.labs.find((l) => l.id === selectedType.value);

        const pcrTests = updateSelectedTestResult.filter((test) => test.test_type === "PCR");
        if (pcrTests.length > 0) {
          await API.submitToLab(lab, pcrTests);
        }
      } else {
        testList = updateSelectedTestResult.map((t) => {
          return {
            id: t.id,
            labID: selectedType.value,
            labName: selectedType.label,
            sequenceNo: t.sequenceNo,
          };
        });
        await GQAPI.updateTestLab(testList);
      }
      addLogs(updateSelectedTestResult, "LabChange", selectedType.label);
      appContext.showSuccessMessage(`${ttlTest} test${ttlTest === 1 ? "" : "s"} lab has been changed`);
    } catch (err) {
      console.log("Error", err);
      appContext.showErrorMessage(`Fail to update the lab`);
    }
    clearSelection();
  };

  const addLogs = (items, slug, lab) => {
    const ids = items.map((s) => {
      const labObj = appContext.labs.find((l) => l.id === s.labID);
      return { id: s.id, sequenceNo: s.sequenceNo, oldValue: labObj ? labObj.name : s.labID, newValue: lab };
    });
    const params = { ids: ids, userID: appContext.user?.sub, userName: appContext.user?.name, slug: slug };
    API.addTestLogs(params);
  };

  const addConfigChangeLogs = async (items, slug, newValue) => {
    try {
      const ids = items.map((s) => {
        const clientObj = appContext.clients.find((l) => l.id === s.clientID);
        const siteObj = appContext.sites.find((l) => l.id === s.siteID);
        return {
          id: s.id,
          sequenceNo: s.sequenceNo,
          oldValue: `${clientObj?.name}-${siteObj?.name}`,
          newValue: newValue,
        };
      });
      const params = { ids: ids, userID: appContext.user?.sub, userName: appContext.user?.name, slug: slug };
      await API.addTestLogs(params);
    } catch (error) {
      console.log("Error in Adding Logs");
    }
  };

  const showAppLogs = async (test) => {
    setShowAppLog(true);
    const logs = await API.getAppTestLogs(test.sequenceNo);
    setTestAppLogs([...logs].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
  };

  const handleEditNumber = (test) => {
    if (test.result) return;
    setEditAccessionNo(test);
  };

  const updateClientConfig = async (clientID, siteID) => {
    const updateSelectedTestResult = selectedTest.filter((test) => checkboxes.includes(test.id));
    const ttlTest = updateSelectedTestResult.length;
    if (!ttlTest) {
      return;
    }
    setLoading(true);

    try {
      if (!loading) setLoading(true);

      const params = updateSelectedTestResult.map((t) => {
        return {
          id: t.id,
          clientID: clientID.value,
          clientName: clientID.label,
          siteID: siteID.value,
          site_name: siteID.label,
          sequenceNo: t.sequenceNo,
        };
      });
      await GQAPI.updateTestClientLab(params);

      await addConfigChangeLogs(updateSelectedTestResult, "ConfigChange", `${clientID.label} - ${siteID.label}`);
      appContext.showSuccessMessage(`${ttlTest} test${ttlTest === 1 ? "" : "s"} Configuration has been changed`);
      setLoading(false);
    } catch (err) {
      console.log("Error", err);
      setLoading(false);
      appContext.showErrorMessage(`Fail to update test configuration`);
    }
    clearSelection();
  };

  const handleCloseCreateTest = () => {
    setTestCreate(_.cloneDeep(TEST_EMPTY_OBJECT));
    setOpenCreator(false);
  };

  const handleOpenCreateTest = () => {
    if (selectedTest && selectedTest.length > 0) {
      let selectedUser = { ...selectedTest[0] };
      const emptyObjectKeys = Object.keys(TEST_EMPTY_OBJECT);
      let newObjValue = { ...TEST_EMPTY_OBJECT };
      for (const objKey of emptyObjectKeys) {
        if (objKey === "sequenceNo") continue;
        if (objKey == "employee_demographics") {
          let demographicObj =
            typeof selectedUser[objKey] === "string" ? JSON.parse(selectedUser[objKey]) : selectedUser[objKey];
          newObjValue[objKey] = { ...demographicObj, phoneNumber: selectedUser["phoneNumber"] };
          if (selectedUser["dob"]) {
            newObjValue[objKey] = { ...demographicObj, dob: moment(selectedUser["dob"]).format("MMDDYYYY") };
          }
        } else {
          newObjValue[objKey] = selectedUser[objKey];
        }
      }
      setTestCreate(newObjValue);
    }
    setOpenCreator(true);
  };

  const createTest = async (testData) => {
    // create test
    testData = {
      ...testData,
      submittedByName: appContext?.user?.name,
      createdBy: appContext?.user?.sub,
      tester_name: appContext?.user?.name,
    };

    // Lab Check barcode is active or not
    const checkLab = labs?.find((item) => item.id === testData.labID && item.barCodeProvided);
    if (checkLab && checkLab.id) {
      let barcode = await API.getLabProvidedBarCode(checkLab.id);
      if (barcode) {
        testData = { ...testData, barcode };
      }
    }

    let newTest = await GQAPI.createTest(testData);
    setLoading(false);
    if (newTest) {
      appContext.showSuccessMessage("Test created successfully");
      handleCloseCreateTest();
    } else {
      appContext.showErrorMessage("Test can't be created");
    }
  };

  const TableRow = ({ test }) => {
    return (
      <tr className="trDataWrapper">
        <td
          className={test.preRegTest ? "ellipsis position-relative table-first-td" : "ellipsis position-relative"}
          style={{ textAlign: "left" }}
        >
          <div className="d-flex align-items-center">
            {/* <Form.Check className="me-2">
              <Form.Check.Input
                type="checkbox"
                value={test.id}
                checked={checkboxes.indexOf(test.id) !== -1}
                onChange={(e) => handleCheckboxChange(e, test.id, test)}
              ></Form.Check.Input>
            </Form.Check> */}

            <ViewOnlyCheckBox
              bgColor="red"
              id={test.id}
              checked={checkboxes.indexOf(test.id) !== -1}
              item={test}
              handleChange={(e) => handleCheckboxChange(e, test.id, test)}
            />

            {test.labStatusTest === "1" ? (
              <i title={test.labOrderDetail?.message} className="fa fa-check-circle" />
            ) : test.labStatusTest === "2" ? (
              <i title={test.labOrderDetail?.message} className="fa fa-exclamation-circle" />
            ) : (
              ""
            )}
          </div>
        </td>
        <td className="table-column ellipsis">{moment(test.createdAt).format("MM-DD-YYYY HH:mm")}</td>

        <td
          className="table-column ellipsis"
          title={test.firstName}
          // onClick={async () => await API.getTestFromAPi(test)}
        >
          {test.firstName}
        </td>
        <td className="table-column ellipsis" title={test.lastName}>
          {test.lastName}
        </td>
        <td className="ellipsis" title={test.dob}>
          {test.dob}
        </td>
        <td
          className="table-column ellipsis text-dark-danger text-dark-danger-hover cursor-pointer text-decoration-none"
          onClick={() => test?.email && window.open(`mailto:${test.email}`)}
          title={test.email}
        >
          {test.email}
        </td>
        <td
          className="table-column ellipsis text-dark-danger text-dark-danger-hover cursor-pointer text-decoration-none"
          onClick={() => {
            test.phoneNumber && window.open(`tel:${test.phoneNumber}`);
          }}
          title={test.phoneNumber && formatPhoneNumber(test.phoneNumber)}
        >
          {test.phoneNumber && formatPhoneNumber(test.phoneNumber)}
        </td>
        {!CONFIG.isWhiteLabel && (
          <td className="table-column ellipsis" style={{ textAlign: "center" }}>
            <a
              style={{ color: "#42cef5" }}
              onClick={() => createMoreInfo("client", test.clientID)}
              onMouseOver={(e) => (e.target.style.cursor = "pointer")}
              title={appContext.getClientName(test.clientID)}
            >
              {appContext.getClientName(test.clientID)}
            </a>
          </td>
        )}
        <td className="table-column ellipsis" style={{ textAlign: "center" }}>
          <a
            style={{ color: "#42cef5" }}
            onClick={() => createMoreInfo("site", test.siteID)}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
            title={appContext.getSiteName(test.siteID)}
          >
            {appContext.getSiteName(test.siteID)}
          </a>
        </td>
        {!CONFIG.isWhiteLabel && (
          <td className="table-column ellipsis" style={{ textAlign: "center" }}>
            <a
              style={{ color: "#42cef5" }}
              onClick={() => createMoreInfo("lab", test.labID)}
              onMouseOver={(e) => (e.target.style.cursor = "pointer")}
              title={appContext.getLabName(test.labID)}
            >
              {appContext.getLabName(test.labID)}
            </a>
          </td>
        )}
        <td className="ellipsis" style={{ textAlign: "center" }} title={test.status}>
          {test.status}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={appContext.parseResult(test)}>
          <span
            className="text-dark-danger text-dark-danger-hover cursor-pointer text-decoration-none"
            onClick={() => displayPdf(test)}
            style={{
              color: RESULT_COLOR[test.result?.toLowerCase()],
            }}
          >
            {appContext.parseResult(test)}
          </span>
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={test.test_type || "-"}>
          {test.test_type || "-"}
        </td>
        <SequenceNo
          num={CONFIG.isWhiteLabel ? test.barcode : test.sequenceNo}
          test={test}
          handleEdit={handleEditNumber}
          showLogs={appContext.showLogs}
        />
        {!CONFIG.isWhiteLabel && (
          <td className="ellipsis" style={{ textAlign: "center" }} title={test.tester_name || "NA"}>
            {test.tester_name || "NA"}
          </td>
        )}
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <Link
            className="text-dark-danger text-dark-danger-hover cursor-pointer text-decoration-none"
            onClick={handleSaveFilter}
            to={{ pathname: "/admin/test/" + test.id, state: { admin: true, type: "totalTest", test: test } }}
          >
            View
          </Link>
        </td>
      </tr>
    );
  };

  const handleOnError = (err) => {
    appContext.showErrorMessage(err.message);
  };

  const afterDeletedTest = (testList) => {
    console.log("afterDeletedTest", testList);
    const tmpList = filteredTests.filter((t) => testList.findIndex((f) => f.id === t.id) === -1);
    setFilteredTests(tmpList);
    setTotalRecord(totalRecord - testList.length);
    clearSelection();
  };

  const handleOnRemoveFile = (data) => {
    console.log("Remove Data", data);
  };

  return showPdf ? (
    <PDFLayout setShowPdf={setShowPdf} testPdf={testPdf} testsLab={testsLab} />
  ) : (
    <>
      <Container fluid>
        <div className="shows-screen-parent">
          <Row className="mx-0">
            <Col md="12">
              <Card className="border-0 mb-1">
                <Card.Body className="p-0">
                  <div className="shows-filter-wrapper">
                    <div className="shows-filter-inner">
                      <Icon
                        handleClick={() => setShowFilter(!showFilter)}
                        title={"Filter"}
                        label={"Filter"}
                        iconType={"filter"}
                      />
                      {/* Date Range */}
                      <FilterSelect setTimeFilter={setTimeFilter} />
                      <Icon
                        handleClick={ () => {
                          if (filteredTests && filteredTests.length > 0) {
                            setAllSelected(!allSelected);
                            toggleCheckboxes(!allSelected);
                          }
                         // await API.newDocumentCreateAPI();
                        }}
                        title={!allSelected ? "Select All" : "Deselect All"}
                        label={!allSelected ? "Select All" : "Deselect All"}
                        iconType={"selectAllIcon"}
                      />

                      {filteredTests.length > 0 && (
                        <>
                          {Object.keys(filter).length > 0 && (
                            <>
                              <TestPDFLayout
                                tests={filteredTests}
                                selectedItem={selectedItem}
                                filter={filter}
                                timeFilter={timeFilter}
                                title="Total Tests"
                                appContext={appContext}
                                sortBy={sortBy}
                              />
                            </>
                          )}
                        </>
                      )}
                      {appContext.user?.phone_number === "+18888888888" ||
                        appContext.user?.phone_number === "+12222222222" ||
                        (CONFIG.isWhiteLabel && (
                          <Icon
                            handleClick={() => appContext.openDeleteConfirmation(selectedTest, afterDeletedTest)}
                            title={"Delete Test"}
                            label={"Delete"}
                            iconType={"binIcon"}
                            disabled={checkboxes.length === 0}
                          />
                        ))}
                      <ExportToExcelData
                        items={filteredTests}
                        selectedItem={selectedItem}
                        appContext={appContext}
                        filter={filter}
                        timeFilter={timeFilter}
                        title="Total Tests"
                        sortBy={sortBy}
                        setLoading={setLoading}
                      />
                      {(appContext.user["custom:role"] === "Admins" || CONFIG.isWhiteLabel) && (
                        <>
                          <Icon
                            handleClick={() => setShowResultModal(true)}
                            title={"Result Tests"}
                            label={"Result Tests"}
                            iconType={"resultsIcon"}
                            disabled={checkboxes.length === 0}
                          />

                          {!CONFIG.isWhiteLabel && (
                            <>
                              <Icon
                                handleClick={() => setShowTestTypeModal(true)}
                                title={"Change Test Type"}
                                label={"Test Type"}
                                iconType={"testNowIcon"}
                                disabled={checkboxes.length === 0}
                              />
                              <Icon
                                handleClick={() => setShowLabChangeModal(true)}
                                title={"Change Lab"}
                                label={"Change Lab"}
                                iconType={"labIcon"}
                                disabled={checkboxes.length === 0}
                              />
                              <Icon
                                handleClick={() => setClientChangeModal(true)}
                                title={"Change Client"}
                                label={"Client"}
                                iconType={"personalizeIcon"}
                                disabled={checkboxes.length === 0}
                              />
                            </>
                          )}

                          {(appContext.user.phone_number == "+12222222222" ||
                            appContext.user.phone_number == "+18888888888") && (
                            <Icon
                              handleClick={() => handleOpenCreateTest()}
                              title={"Create Test"}
                              label={"Create Test"}
                              iconType={"createIcon"}
                            />
                          )}
                        </>
                      )}
                      <Icon
                        handleClick={handleExportCalrediePatient}
                        title={"Export to Calredie"}
                        label="Export to H&S"
                        iconType={"XLSIcon"}
                      />
                      <Icon
                        handleClick={handleUploadLogs}
                        title={"H&S Logs"}
                        label="H&S Logs"
                        iconType={"importIcon"}
                      />
                    </div>
                  </div>
                  {showFilter && (
                    <Filter
                      filterTerms={searchTerms}
                      setFilter={setFilter}
                      filter={filter}
                      triggerFilter={triggerFilter}
                      setTriggerFilter={setTriggerFilter}
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mx-0">
            <Col md="12">
              <Card className="striped-tabled-with-hover bg-transparent">
                <Card.Header className="shows-screen-wrapper">
                  <Row className="pb-4">
                    <Col md-="6">
                      <Card.Title>
                        <div className="">
                          Total Tests:
                          <span className="ps-2">{totalRecord}</span>
                        </div>
                      </Card.Title>
                    </Col>
                    <Col md="6">
                      <PGPagination
                        timeFilter={timeFilter}
                        currentPage={currentPage}
                        handleChangePage={handleChangePage}
                        totalPages={totalPages}
                        totalRecord={totalRecord}
                        currentRecord={filteredTests.length}
                        showSelectedRecord
                        totalSelected={checkboxes.length}
                      />
                    </Col>
                  </Row>
                  {/* <div className="buttonHeader">
                    <Button className="headerButton" onClick={() => setShowFilter(!showFilter)}>
                      Filter
                    </Button>
                    <FilterSelect setTimeFilter={setTimeFilter} />
                    <Button
                      className="headerButton"
                      onClick={() => {
                        if (filteredTests && filteredTests.length > 0) {
                          setAllSelected(!allSelected);
                          toggleCheckboxes(!allSelected);
                        }
                      }}
                    >
                      {!allSelected ? "Select All" : "Deselect All"}
                    </Button>
                    {filteredTests.length > 0 && (
                      <>
                        {Object.keys(filter).length > 0 && (
                          <>
                            <ExportToExcelData
                              items={filteredTests}
                              selectedItem={selectedItem}
                              appContext={appContext}
                              filter={filter}
                              timeFilter={timeFilter}
                              title="Total Tests"
                              sortBy={sortBy}
                              setLoading={setLoading}
                            />
                            <TestPDFLayout
                              tests={filteredTests}
                              selectedItem={selectedItem}
                              filter={filter}
                              timeFilter={timeFilter}
                              title="Total Tests"
                              appContext={appContext}
                              sortBy={sortBy}
                            />
                          </>
                        )}
                      </>
                    )}
                    {(appContext.user?.phone_number === "+18888888888" ||
                      appContext.user?.phone_number === "+12222222222") && (
                      <Button
                        className="headerButton"
                        disabled={checkboxes.length === 0}
                        onClick={() => setOpenDeleteConfirmation(true)}
                      >
                        Delete Test
                      </Button>
                    )}
                    {appContext.user["custom:role"] === "Admins" && (
                      <>
                        <Button
                          className="headerButton"
                          onClick={() => setShowResultModal(true)}
                          disabled={checkboxes.length === 0}
                        >
                          Change Result
                        </Button>
                        <Button
                          className="headerButton"
                          onClick={() => setShowTestTypeModal(true)}
                          disabled={checkboxes.length === 0}
                        >
                          Change Test Type
                        </Button>
                        <Button
                          className="headerButton"
                          onClick={() => setShowLabChangeModal(true)}
                          disabled={checkboxes.length === 0}
                        >
                          Change Lab
                        </Button>
                        <Button
                          className="headerButton"
                          disabled={checkboxes.length === 0}
                          onClick={() => setClientChangeModal(true)}
                        >
                          Change Client
                        </Button>
                        {(appContext.user.phone_number == "+12222222222" ||
                          appContext.user.phone_number == "+18888888888") && (
                          <Button className="headerButton" onClick={() => handleOpenCreateTest()}>
                            Create Test
                          </Button>
                        )}
                      </>
                    )}
                  </div> */}
                </Card.Header>

                <Card.Body className="table-full-width px-0 desktop-noScroll mt-0 pt-0">
                  <div className="table-responsive pendingReleaseTable">
                    <Table className="separate-border-tr" ref={mainRef}>
                      <Header
                        type={TEST_HEADER_TYPE.alltests}
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        widthCol={55}
                      />
                      <tbody>
                        {testsToMap &&
                          testsToMap.map((test) => {
                            return <TableRow key={test.id} test={test} />;
                          })}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
      <ContactInfo show={modalShow} moreInfo={moreInfo} handleClose={handleCloseContactInfo} />
      {showResultModal && (
        <ChangeResultModal
          handleClose={() => setShowResultModal(false)}
          appContext={appContext}
          tests={selectedTest.filter((test) => checkboxes.includes(test.id))}
          callback={clearSelection}
        />
      )}
      {showTestTtypeModal && (
        <ChangeTestTypeModal handleSave={updateTestType} handleClose={() => setShowTestTypeModal(false)} />
      )}
      {showLabChangeModal && (
        <ChangeTestLabModal
          handleSave={updateLabChange}
          selectedTest={selectedTest}
          labs={appContext.labs}
          handleClose={() => setShowLabChangeModal(false)}
        />
      )}
      {clientChangeModal && (
        <ChangeTestConfigModal
          handleSave={updateClientConfig}
          clients={appContext.clients}
          sites={appContext.sites}
          handleClose={() => setClientChangeModal(false)}
        />
      )}
      {openDeleteConfirmation && (
        <ConfirmationModal
          show={openDeleteConfirmation}
          title="Delete Test"
          message={`Are you sure, you want to remove ${checkboxes.length} Test${checkboxes.length > 1 ? "s" : ""}`}
          handleConfirm={handleDeleteConfirm}
        />
      )}

      {showAppLog && (
        <AppTestLogsModal
          data={testAppLogs}
          handleClose={() => {
            setShowAppLog(false);
            setTestAppLogs([]);
          }}
        ></AppTestLogsModal>
      )}

      {editAccessionNo && (
        <EditAccessionNoModal
          handleClose={(val) => {
            setEditAccessionNo(null);
            if (val) {
              val === "success"
                ? appContext.showSuccessMessage("Successfully Changed the Accession No!")
                : appContext.showErrorMessage(val);
            }
          }}
          test={editAccessionNo}
          appContext={appContext}
        />
      )}

      {openUploadLogsModal && (
        <CalRedieLogsModal
          handleClose={() => setOpenUploadLogsModal(false)}
          user={appContext.user}
          appContext={appContext}
        />
      )}

      {openCreator && (
        <TestModal
          newTestCreate={newTestCreate}
          handleCloseCreateTest={handleCloseCreateTest}
          createTest={createTest}
          setTestCreate={setTestCreate}
          clients={appContext.clients}
          sites={appContext.sites}
          labs={appContext.labs}
        />
      )}
      {loading && <Loader />}
    </>
  );
};

export default AdminTotalTestApi;
